.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Import Roboto Font */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

/* General layout changes */
body {
  font-family: 'Roboto', sans-serif;
  background: linear-gradient(to right, #ffffff, #ffffff);
  color: #000000;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: 0;
}

.App-header {
  background-color: #002868; /* Dark blue for header */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

h1 {
  font-size: 2rem;
  margin-bottom: 20px;
  color: #ffffff;
  text-transform: uppercase;
  letter-spacing: 2px;
  text-align: center;
}

/* Main Wrapper */
.main-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding-top: 20px;
}

/* Toggle Button (Top Red Button) */
.toggle-button {
  width: 200px;
  padding: 10px;
  margin: 10px 0;
  border: none;
  border-radius: 20px;
  background: #BF0A30; /* Bright red for button */
  color: #fff;
  font-size: 14pt;
  font-family: 'Roboto', sans-serif;
  cursor: pointer;
  transition: background 0.3s ease;
}
.toggle-button:hover {
  transform: scale(1.1);
}

/* Chat Container */
.container {
  background-color: #002868; /* Dark blue background */
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  width: 750px;
  display: flex;
  flex-direction: column;
  max-height: 90vh;
}

.chat-window {
  flex: 1;
  overflow-y: auto;
  padding: 10px;
  margin-bottom: 10px;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 10px;
  height: 400px;
  max-height: 400px;
  min-height: 400px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.message {
  margin: 10px 0;
  padding: 7px;
  border-radius: 10px;
  animation: fly-in 0.5s forwards;
  align-self: flex-start;
  max-width: 100%;
  word-wrap: break-word;
  line-height: 1.4;
}

.user-message {
  background-color: #BF0A30; /* Bright red for user messages */
  color: #fff;
  text-align: right;
  align-self: flex-end;
}

.bot-response {
  background-color: #005bb5; /* Lighter blue for bot responses */
  color: #fff;
  text-align: left;
  align-self: flex-start;
}

/* Input Area */
textarea {
  width: 100%;
  min-height: 40px;
  max-width: 100%;
  padding: 10px;
  margin: 10px 0;
  border-radius: 20px;
  border: 1px solid rgba(255, 255, 255, 0);
  background-color: rgba(255, 255, 255, 0.2);
  color: #ffffff;
  font-size: 12pt;
  resize: none;
  overflow: hidden;
  font-family: 'Roboto', sans-serif;
  box-sizing: border-box;
}

.submit-button {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border: none;
  border-radius: 20px;
  background: #BF0A30;
  color: #fff;
  font-size: 14pt;
  cursor: pointer;
  transition: background 0.3s ease;
}

.submit-button:hover {
  background: #730d0d; /* Darker red on hover */
}

/* Side Containers */
.side-container {
  background-color: #002868;
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  width: 250px;
  margin-left: 20px;
  color: #fff;
  font-family: 'Roboto', sans-serif;
}

/* Form elements */
select,
input[type="text"] {
  width: 200px;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  background-color: rgba(255, 255, 255, 0.1);
  color: #fff;
  font-size: 1rem;
  margin-bottom: 10px;
}

input[type="text"]::placeholder {
  color: #ccc;
}

/* Bill list styling */
.bill-list-container {
  margin-left: 20px;
  width: 300px;
  background-color: #BF0A30; /* Red background */
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  color: #fff;
  font-family: 'Roboto', sans-serif;
  max-height: 600px;
  overflow-y: auto;
}

.bill {
  margin-bottom: 10px;
  padding: 10px;
  background-color: #002868;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
}

.bill-number {
  font-size: 18px;
  font-weight: bold;
}

.bill-title {
  font-size: 14px;
  margin-left: 10px;
}

.bill button {
  background-color: rgba(36, 218, 23, 0.8);
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  font-size: 15px;
  transition: background 0.3s ease;
}

.bill button:hover {
  background-color: rgba(73, 73, 73, 0.8);
}

/* Animations */
@keyframes fly-in {
  0% {
    transform: translateY(20px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes fly-in-sidebar {
  0% {
      transform: translateX(100%);
      opacity: 0;
  }
  100% {
      transform: translateX(0);
      opacity: 1;
  }
}

